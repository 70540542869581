<section class="w-full">
  @switch (status()) {
    @case (error) {
      <div class="flex flex-col items-center justify-center gap-2 text-gray-700">
        <div class="w-10 h-10 bg-red-500 rounded-full flex items-center justify-center animate-scale-in">
          <mat-icon class="text-white">priority_high</mat-icon>
        </div>
        <div class="flex items-center">
          <span>{{ responseData().detail }}</span>
        </div>
      </div>
    }
    @case (multipleAccounts) {
      <div class="flex flex-col items-center justify-center gap-2 text-gray-700">
        <div class="w-10 h-10 bg-red-500 rounded-full flex items-center justify-center animate-scale-in">
          <mat-icon class="text-white">priority_high</mat-icon>
        </div>
        <div class="flex items-center">
          <span
            >Unable to recover username: The email or phone number is linked to multiple accounts. Please contact
            <a [routerLink]="['contact']" class="text-blue-500 underline hover:text-blue-700" (click)="closeDialog()"
              >customer support</a
            >
            for assistance.</span
          >
        </div>
      </div>
    }

    @case (approved) {
      <div class="flex flex-col items-center justify-center gap-2 text-gray-700">
        <div class="w-10 h-10 bg-green-500 rounded-full flex items-center justify-center animate-scale-in">
          <mat-icon class="text-white">check</mat-icon>
        </div>
        <div class="flex items-center">
          <span>{{ responseData().detail }}</span>
        </div>
      </div>
    }
    @default {}
  }
</section>
