import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Payment } from '../models/payments.model';

@Injectable()
export class PaymentService {
  private paymentSubject = new BehaviorSubject<Payment>(init);
  private paymentBankSubject = new BehaviorSubject<Payment>(init);

  getDetails() {
    return this.paymentSubject;
  }

  getValue() {
    return this.paymentSubject.getValue();
  }

  setDetails(details: any) {
    this.paymentSubject.next(details);
  }

  resetDetails() {
    this.paymentSubject.next(init);
  }

  getBankDetails() {
    return this.paymentBankSubject;
  }

  getBankValue() {
    return this.paymentBankSubject.getValue();
  }

  setBankDetails(details: any) {
    this.paymentBankSubject.next(details);
  }

  resetBankDetails() {
    this.paymentBankSubject.next(init);
  }
}

export const init: Payment = {
  id: '',
  account: {
    id: '',
    accountNumber: '',
  },
  authorizationSource: {
    code: '',
  },
  isNew: false,
  requestSource: '',
  paidAmount: 0,
  save: false,
  useAsRecurring: false,
  valid: false,
  type: null,
  paymentMethodData: null,
  typeBankAccount: '',
};
