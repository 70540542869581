export enum VerificationStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Canceled = 'Canceled',
  MaxAttemptsReached = 'MaxAttemptsReached',
  MaxCheckAttemptsReached = 'MaxCheckAttemptsReached',
  Deleted = 'Deleted',
  Failed = 'Failed',
  Expired = 'Expired',
  Error = 'Error',
  MultipleAccounts = 'MultipleAccounts',
}
