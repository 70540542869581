import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { RouterLink, RouterOutlet } from '@angular/router';
import { RequestResponse } from '@src/app/mepco-public/header-tabs/home/components/logIn/dialog/forgot-username/forgot-username-dialog.component';
import { VerificationStatus } from '@src/app/mepco-public/models/verification-status.model';

@Component({
  selector: 'app-response-viewer',
  templateUrl: './response-viewer.component.html',
  styleUrls: ['./response-viewer.component.scss'],
  standalone: true,
  imports: [MatIcon, CommonModule, RouterLink, RouterOutlet],
})
export class ResponseViewerComponent {
  error: VerificationStatus = VerificationStatus.Error;
  approved: VerificationStatus = VerificationStatus.Approved;
  multipleAccounts: VerificationStatus = VerificationStatus.MultipleAccounts;

  responseData = input<RequestResponse | null>();
  dialogRef = input<MatDialogRef<unknown>>();

  status = computed(() => this.checkStatus());

  checkStatus() {
    switch (this.responseData()?.status) {
      case VerificationStatus.Approved:
        return VerificationStatus.Approved;
      case VerificationStatus.Pending:
        return VerificationStatus.Approved;
      case VerificationStatus.Error:
      case VerificationStatus.MaxAttemptsReached:
      case VerificationStatus.MaxCheckAttemptsReached:
      case VerificationStatus.Failed:
        return VerificationStatus.Error;
      case VerificationStatus.MultipleAccounts:
        return VerificationStatus.MultipleAccounts;
      default:
        break;
    }
    return;
  }

  closeDialog() {
    const dialogRef = this.dialogRef();
    if (this.dialogRef) {
      dialogRef.close();
    }
  }
}
